import { Outlet } from "react-router-dom";
import Navbar from "../../main/mainPages/Navbar";
import Sidebar from "../../main/mainPages/sidebar";
import React, { useState } from "react";

const PostLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex flex-col h-screen">
      <Navbar toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />

      <div className="flex flex-1 overflow-hidden">
        <div className={`w-56 overflow-y-auto ${isSidebarOpen ? "block" : "hidden"} md:block`}>
          <Sidebar isSidebarOpen={isSidebarOpen} />
        </div>

        <div className="flex-1 p-2 overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PostLayout;