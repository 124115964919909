import PostList from '../components/post-list';

const PostSaves = () => {
  return (
    <div className="container">
      <PostList endpoint="/post/get-user-save-posts/" />
    </div>
  );
};

export default PostSaves;