// MyDonorCommunity.js
import React from "react";
import { useLocation, Navigate } from "react-router-dom";

const MyDonorCommunity = () => {
  const location = useLocation();
  const membershipData = location.state;

  // Redirect if no membership data is present
  if (!membershipData) {
    return <Navigate to="/" replace />;
  }

  const { startDate, endDate, plan, isActive } = membershipData;

  return (
    <div className="p-4 max-w-md mx-auto mt-20">
      <div className="bg-white rounded-lg shadow-md p-6 dark:bg-gray-800">
        <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
          Donor Membership Details
        </h2>
        <div className="space-y-3">
          <div className="flex justify-between items-center border-b pb-2">
            <span className="font-medium text-gray-600 dark:text-gray-300">Plan:</span>
            <span className="text-gray-800 dark:text-white">{plan}</span>
          </div>
          <div className="flex justify-between items-center border-b pb-2">
            <span className="font-medium text-gray-600 dark:text-gray-300">Status:</span>
            <span className={`px-3 py-1 rounded-full ${
              isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
            }`}>
              {isActive ? "Active" : "Inactive"}
            </span>
          </div>
          <div className="flex justify-between items-center border-b pb-2">
            <span className="font-medium text-gray-600 dark:text-gray-300">Start Date:</span>
            <span className="text-gray-800 dark:text-white">{startDate}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="font-medium text-gray-600 dark:text-gray-300">End Date:</span>
            <span className="text-gray-800 dark:text-white">{endDate}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDonorCommunity;