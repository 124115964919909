import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BiUpvote, BiDownvote  } from 'react-icons/bi';
import { FaComment, FaEye, FaHandHoldingUsd, FaExclamationTriangle } from 'react-icons/fa';

const PostCard = ({ post }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/detail/${post.slug}`);
  };

  return (
    <div
      onClick={handleClick}
      className="cursor-pointer bg-white dark:bg-gray-800 rounded-2xl shadow-lg transition-transform transform hover:scale-105 w-auto"
    >
      <div className="p-4">
        {/* Description */}
        <p className="text-gray-700 dark:text-gray-300 text-sm h-auto mb-2 truncate-2-lines">
          {post.description.length > 10090
            ? `${post.description.substring(0, 100)}...`
            : post.description}
        </p>

        {/* Divider */}
        <hr className="my-3 border-t border-gray-200 dark:border-gray-600" />

        {/* Stats */}
        <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 gap-2 text-center">
          <StatButton label="comments" count={post.comment_count} icon={<FaComment className="text-xl" />} />
          <StatButton label="ups" count={post.upvote_count} icon={<BiUpvote className="text-xl" />} />
          <StatButton label="downs" count={post.downvote_count} icon={<BiDownvote className="text-xl" />} />
          <StatButton label="donors" count={post.donors_count} icon={<FaHandHoldingUsd className="text-xl" />} />
          <StatButton label="views" count={post.views.length} icon={<FaEye className="text-xl" />} />
          <StatButton label="reports" count={post.report_count} icon={<FaExclamationTriangle className="text-xl" />} />
        </div>
      </div>
    </div>
  );
};

const StatButton = ({ label, count, icon }) => {
  return (
    <div className="flex flex-col items-center">
      {/* <div className="text-gray-600 dark:text-gray-400 ">{icon}</div> */}
      <p className="font-semibold text-gray-600 dark:text-gray-400 text-xs">{label}</p>
      <span className="font-bold text-gray-800 dark:text-white text-xs">{count}</span>
    </div>
  );
};

export default PostCard;