
import Modal from "./Modal";
import CustomButton from "./CustomButton";
import React, { useState } from 'react';
import useSupportSeekerModal from "../hooks/useSupportSeekerModal";
import { useDonateMutation } from "../../services/postApis";

import { useGetPostBankDetailQuery } from "../../services/postApis";



const SupportSeekerModal = ({ slug }) => {

  const [donateMutation] = useDonateMutation();
  const [selectedOption, setSelectedOption] = useState(0);
  const SupportSeekerModal = useSupportSeekerModal();
  const {data: bankData, isLoading } = useGetPostBankDetailQuery(slug, {skip: !SupportSeekerModal.isOpen})

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmitDonation = async () => {
    const formData = new FormData();
    formData.append("slug", slug);
    formData.append('amount', selectedOption);

    try {
      const response = await donateMutation(formData); // Await the API call
      console.log(response);
      setSelectedOption(0); // Reset the amount field
      SupportSeekerModal.close();

      setTimeout(() => {
      }, 5000); // 10,000 milliseconds = 10 seconds

    } catch (error) {
      console.error("Error:", error);
    }
  }

  const content = (
    <>
      
      <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-4">
        <div className="text-slate-950 dark:text-stone-200">
          <p className="text-md mt-5 font-bold">First Step</p>
          <p>
            Verify the seeker's address and bank details by calling them, and confirm their identity and eligibility to receive Zakat. If the seeker lives near you, then do visit.
          </p>
        </div>
        <div className="text-slate-950 dark:text-stone-200">
          <p className="text-md mt-5 font-bold">Second Step</p>
          <p>
            Make payments directly to the seeker through safe and secure methods such as internet banking or delivery services. Ensure that the payment reaches them without any delay or inconvenience.
          </p>
        </div>
        <div className="text-slate-950 dark:text-stone-200">
          <p className="text-md mt-5 font-bold">Third Step</p>
          <p>
            After making the payment, please inform us of the amount you paid so that we can keep a record and track the payment. This will help us ensure that Zakat reaches those in need in a timely and efficient manner.
          </p>
        </div>

        <div className="text-slate-950 dark:text-stone-200">
          <p className='text-xl font-bold'>Seeker Bank Details</p>

          {bankData? (
            <div className="space-x-3">
              <p className='font-semibold'>Account Holder: {bankData.account_holder} </p>
              <p className='font-semibold'>Bank Name: {bankData.bank_name} </p>
              <p className='font-semibold'>Account Number: {bankData.account_number} </p>
            </div>
          ): (
            !isLoading && (
              <p className="text-slate-950 dark:text-stone-200">
                Bank Detail can not be shown right now
              </p>
            )
          )}
         
        </div>
        
      </div>
      
      <form onSubmit={(e) => handleSubmitDonation()} className="space-y-4">
        <div className="flex flex-col text-slate-950 dark:text-stone-200">
          
          <div className="space-y-2">
            <div className="">
              Kinldy write in amount in pkr
            </div>
            <div>
            <input
              type="number"
              value={selectedOption}
              onChange={handleOptionChange}
              className="text-dark-1 dark:text-white dark:bg-black border border-gray-300 rounded p-2 w-full"
            />
            </div>
          </div>
{/* 
          <div className="space-y-2 mt-5">
            <div className="">
              Kindly upload the payment slip
            </div>
            <div>
            <input
              type="file"
              value={selectedOption}
              onChange={handleOptionChange}
              className="text-dark-1 dark:text-white dark:bg-black border border-gray-300 rounded p-2 w-full"
            />
            </div>
          </div> */}

        </div>
        <div className="flex justify-between">
          <CustomButton 
            label="Submit"  
            onClick={handleSubmitDonation}
            className={"bg-blue-500"}
          />
        </div>
      </form>

    </>
  )

  return (
    <Modal 
      isOpen={SupportSeekerModal.isOpen}
      close={SupportSeekerModal.close}
      label="Donation"
      content={content}
    />
  )
}

export default SupportSeekerModal