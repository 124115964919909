import React from 'react';
import { useLocation } from 'react-router-dom';
import PostList from '../components/post-list';

const PostFilter = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search).toString();

  return (
    <div className="container">
      <PostList endpoint="/post/filter-posts/" queryParams={queryParams} />
    </div>
  );
};

export default PostFilter;