import PostList from '../components/post-list';

const PostHistory = () => {
  return (
    <div className="container">
      <PostList endpoint="/post/get-user-history/" />
    </div>
  );
};

export default PostHistory;