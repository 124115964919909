import Modal from "./Modal"
import usePostDeleteModalHook from "../hooks/usePostDeleteModal"

const DeletePostModal = ({slug}) => {
  const useHook = usePostDeleteModalHook()
  const content = (
    <>
      <h1>slug: {slug}</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum illo maiores dolor fuga dignissimos, quibusdam quis odio hic animi, ex explicabo distinctio consectetur porro necessitatibus doloremque minima aut neque debitis!
      </p>
    </>
  ) 

  return (
    <>
      <Modal 
        isOpen={useHook.isOpen}
        close={useHook.close}
        label="Update Post"
        content={content}
      />
    </>

  )

}


export default DeletePostModal;