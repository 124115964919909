import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { GrAdd } from 'react-icons/gr';
import AccountMenu from './menu';
import { getToken } from '../../storage/LocalStorageService';
import useCreatePost from '../../post/hooks/useCreatePost';
import CreatePostModal from '../../post/modals/CreatePostModal';
import { useGetUserMembershipQuery } from '../../services/userAuthApi';

const Navbar = ({ toggleSidebar }) => {
  const { access_token } = getToken();
  const { isOpen, open, close } = useCreatePost();
  const navigate = useNavigate();
  const { data: membershipData, isLoading, isError } = useGetUserMembershipQuery();

  const handleCreatePostClick = () => {
    if (access_token) {
      open();
    } else {
      navigate('/auth/login');
    }
  };

  useEffect(() => {
    if (membershipData?.data?.is_active !== undefined) {
      localStorage.setItem('is_donor', membershipData.data.is_active);
    }
  }, [membershipData]);

  const handleJoinDonorCommunity = () => {
    if (access_token) {
      if (membershipData?.data?.is_active){
        const membershipState = {
          startDate: membershipData.data.starts,
          endDate: membershipData.data.ends,
          plan: membershipData.data.plan,
          isActive: membershipData.data.is_active,
          donorCount: membershipData.membership_count
        };
        navigate('/my-donor-community', {
          state: membershipState
        });
      } else {
        navigate('/join-donor-community');
      }
    } else {
      navigate('/auth/login');
    }
  };

  return (
    <>
      <nav className="bg-white top-0 flex items-center justify-between w-full fixed z-50 dark:bg-gray-700 shadow-md">
        <div className="flex items-center m-2">
          <button 
            className="block md:hidden" 
            onClick={toggleSidebar}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-9 h-9 text-black font-extrabold dark:text-white hover:cursor-pointer dark:hover:bg-gray-800 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
          
          <NavLink to="/" className="text-2xl text-dark font-bold dark:text-white hover:text-gray-600 transition-colors">
            AddaZakat
          </NavLink>
        </div>

        <ul className="flex space-x-4 items-center">
          {isLoading && isError ? (
            <li className="hidden md:flex font-extrabold space-x-1 items-center border border-dark-1 bg-gray-100 p-2 m-1 rounded-full dark:text-white dark:border-gray-50 dark:bg-gray-600 opacity-50 cursor-not-allowed">
              <p className="text-sm">Loading...</p>
            </li>
          ) : (
            <li
              onClick={handleJoinDonorCommunity}
              className={`hidden md:flex font-extrabold space-x-1 items-center border border-dark-1 bg-gray-100 p-2 m-1 hover:bg-gray-200 rounded-full dark:text-white dark:border-gray-50 hover:cursor-pointer dark:bg-gray-600 dark:hover:bg-gray-800 transition-colors ${
                membershipData?.data?.is_active ? 'text-green-600' : 'text-black'
              }`}
            > 
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5">
                <path fillRule="evenodd" d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z" clipRule="evenodd" />
                <path d="M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z" />
              </svg>
              <p className="text-sm">  
                {membershipData?.data?.is_active ? 'Donor Community' : 'Join Donor Community'}
              </p>
            </li>
          )}

          <li
            onClick={handleCreatePostClick}
            className=" hidden md:flex text-black font-extrabold space-x-1 items-center border border-dark-1 bg-gray-100 p-2 m-1 hover:bg-gray-200 rounded-full dark:text-white dark:border-gray-50 hover:cursor-pointer dark:bg-gray-600 dark:hover:bg-gray-800 transition-colors"
          >
            <GrAdd className="w-5 h-5" />
            <p className="text-sm">Create Post</p>
          </li>

          <li>
            <AccountMenu />
          </li>
        </ul>

        {isOpen && <CreatePostModal onClose={close} />}
      </nav>
    </>
  );
};

export default Navbar;