import Modal from "./Modal"
import useCreatePost from "../hooks/useCreatePost"
import React, { useEffect, useState } from 'react';
import "../assets/css/create-post.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import axiosRetry from 'axios-retry';


const CreatePostModal = () => {
  const useCreatePostModal = useCreatePost()
  
  // States
  const [currentState, setCurrentStep] = useState(1);
    
  const [seekerVideoMessage, setSeekerVideoMessage] = useState('');
  const [seekerVideoInProcess, setSeekerVideoInProcess] = useState(false);
  const [placeVideoInProcess, setPlaceVideoInProcess] = useState(false);
  const [docInProcess, setDocInProcess] = useState(false);
  const [placeVideoMessage, setPlaceVideoMessage] = useState('');
  const [docUploadMessage, setDocUploadMessage] = useState('');
  const [postDataErrorMessage, setPostDataErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [VideoObject, setVideoObject] = useState(0);
  const [postSlug, setPostSlug] = useState('');

  const [selectedFiles, setSelectedFiles] = useState(null);

  const navigate = useNavigate();
  axiosRetry(axios, { retries: 3 }); 


  const [formData, setFormData] = useState({
    post_type: '',
    need: '',
    seeker: '',
    description: '',
    address: '',
    phone_number: '',
    needed_money: 0,
    verified: 0,
  });


  const [bankFormData, setBankFormData] = useState({
    bank_name: '',
    account_number: '',
    account_holder: '',
    slug: '',
  });

  const [showBankDetailFields, setShowBankDetailFields] = useState(true)

  useEffect (() => {
    setBankFormData((prevData) => ({
      ...prevData,
      slug: postSlug,
    }));

  }, [postSlug])

  const handlePostDataChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: val,
    }));
  };

  const handleBankDataChange = (e) => {
    setBankFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
    

  const handleSeekerFileChange = async (e) => {
    const file = e.target.files[0];
    
    if (!file) {
      setSeekerVideoMessage('No file selected.');
      return;
    }
  
    if (file.size > 500 * 1024 * 1024) {
      setSeekerVideoMessage('File size exceeds the 500MB limit.\nOr it should be less than 7 minutes.');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
    formData.append('VideoObject', VideoObject);
    formData.append('slug', postSlug);

  
    setSeekerVideoInProcess(true);
    setSeekerVideoMessage('AI Verifying seeker video...');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/post/upload-seeker-videos/`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'multipart/form-data',
          },
          timeout: 500000,
        }, 
      );
  
      if (response.status === 200) {
        setCurrentStep(4);
        setVideoObject(response.data.VideoObject)
        setSeekerVideoMessage(response.data.message);
        setSeekerVideoInProcess(false)
        setPostDataErrorMessage("")

      } else {
        setSeekerVideoMessage(response.data.error || 'An unexpected error occurred');
      }
    } catch (error) {
      console.error('An error occurred while uploading the seeker video');
      console.error('Error uploading seeker video:', error);
  
      if (error.response) {
        const errorMsg =error.response.data.error || `Error ${error.response.status}: ${error.response.statusText}`;
        setSeekerVideoMessage(errorMsg);
      } else {
        setSeekerVideoMessage('Network error occurred while uploading the seeker video.');
      }
    } finally {
      setSeekerVideoInProcess(false);
    }
    
  };

  const handlePlaceFileChange = async (e) => {
    const file = e.target.files[0];
    
    if (!file) {
      setPlaceVideoMessage('No file selected.');
      return;
    }
  
    if (file.size > 500 * 1024 * 1024) {
      setPlaceVideoMessage('File size exceeds the 500MB limit.\nOr it should be less than 7 minutes.');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file,);
    formData.append('VideoObject', VideoObject);
    formData.append('slug', postSlug);

    setPlaceVideoInProcess(true)
    setPlaceVideoMessage('AI Verifying place video...');

    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_DOMAIN + '/post/upload-place-videos/', formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setCurrentStep(5);
        setVideoObject(response.data.VideoObject)
        setPlaceVideoMessage(response.data.message);
        setPlaceVideoInProcess(false)
        setPostDataErrorMessage("")


      } else {
        setPlaceVideoInProcess(false)
        setPlaceVideoMessage(response.data.error || 'An unexpected error occurred');
      }

    } catch (error) {
      setPlaceVideoInProcess(false);
      if (error.response && error.response.status === 400) {
        setPlaceVideoMessage(error.response.data.error || 'Bad Request: Something went wrong');
      } else {
        setPlaceVideoMessage('An error occurred while uploading the place video');
      }
    }finally {
      setSeekerVideoInProcess(false);
    }
  };

  const handleFileSelection = (e) => {
    const files = e.target.files;
    
    if (!files.length) {
      setDocUploadMessage('No files selected.');
      setSelectedFiles(null);
      return;
    }
  
    // Allowed file types
    const allowedTypes = [
      // Documents
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      'text/plain',
      // Images
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'image/webp',
      'image/heic',
      'image/heif'
    ];
  
    let validFiles = true;
    let invalidFileMessage = '';
  
    Array.from(files).forEach(file => {
      // Check file size (100MB limit)
      if (file.size > 100 * 1024 * 1024) {
        invalidFileMessage = `File size for ${file.name} exceeds the 100MB limit.`;
        validFiles = false;
        return;
      }
  
      // Check file type
      if (!allowedTypes.includes(file.type)) {
        invalidFileMessage = `File type for ${file.name} is not allowed. Please upload only documents or images.`;
        validFiles = false;
        return;
      }
    });
  
    if (validFiles) {
      setSelectedFiles(files);
      setDocUploadMessage('Files selected and ready for upload.');
    } else {
      setSelectedFiles(null);
      setDocUploadMessage(invalidFileMessage);
    }
  };

  const handleFileSubmit = async (e) => {
    e.preventDefault();
    
    if (!selectedFiles) {
      setDocUploadMessage('Please select files first.');
      return;
    }

    setDocInProcess(true);
    setIsLoading(true);
    setDocUploadMessage('Uploading documents...');

    const formData = new FormData();
    formData.append('slug', postSlug);
    
    Array.from(selectedFiles).forEach(file => {
      formData.append('files', file);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/post/upload-post-docs/`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        setDocUploadMessage('Documents uploaded successfully.');
        navigate('/detail/' + postSlug);
        setPostDataErrorMessage('');
        setDocUploadMessage('');
        useCreatePostModal.close();
      } else {
        setDocUploadMessage('An unexpected error occurred.');
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data) {
        const errorData = error.response.data;

        if (errorData?.error && Array.isArray(errorData.error.error)) {
          setPostDataErrorMessage(errorData.error.error[0]); 
        } 
        else if (errorData?.error.documents){
          setPostDataErrorMessage("documents: " + errorData.error.documents[0]); 
        } 
        else if (typeof errorData?.error === "string") {
          setPostDataErrorMessage(errorData.error);
        } else {
          setPostDataErrorMessage("An unknown error occurred.");
        }
      }else {
        setPostDataErrorMessage('An unexpected error occurred. Please try again.');
      };
    } finally {
      setDocInProcess(false);
      setIsLoading(false);
    }
  };

  const SubmitPostData = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    const jsonData = JSON.stringify(formData);
  
    // Create post
    const config = {
      headers: {
        'Content-Type': 'application/json', // Use JSON content type
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      }
    };

    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_DOMAIN +'/post/create-post/', jsonData, config);
      setIsLoading(false)

      if (response.status === 201) {
        setPostSlug(response.data.slug);
        setCurrentStep(2);
        setPostDataErrorMessage("")
      }
    } catch (error) {

      setIsLoading(false);
      if (error.response && error.response.data) {
        const errorData = error.response.data;

        if (errorData?.error && Array.isArray(errorData.error.error)) {
          setPostDataErrorMessage(errorData.error.error[0]); 
        } 
        else if (errorData?.error.seeker){
          setPostDataErrorMessage("Seeker: " + errorData.error.seeker[0]); 
        } 
        else if (errorData?.error.address){
          setPostDataErrorMessage("Address: " + errorData.error.address[0]); 
        } 
        else if (errorData?.error.phone_number){
          setPostDataErrorMessage("Phone Number: " + errorData.error.phone_number[0]); 
        } 
        else if (errorData?.error.needed_money){
          setPostDataErrorMessage("How much money do you need?: " + errorData.error.needed_money[0]); 
        } 
        else if (errorData?.error.post_type){
          setPostDataErrorMessage("Asking for What?: " + errorData.error.post_type[0]); 
        } 
        else if (errorData?.error.need){
          setPostDataErrorMessage("What you need?: " + errorData.error.need[0]); 
        } 
        else if (errorData?.error.description){
          setPostDataErrorMessage("Description: " + errorData.error.description[0]); 
        } 
        else if (typeof errorData?.error === "string") {
          setPostDataErrorMessage(errorData.error);
        } else {
          setPostDataErrorMessage("An unknown error occurred.");
        }
      }else {
        setPostDataErrorMessage('An unexpected error occurred. Please try again.');
      };
    };
  };

  const SubmitPostBankData = async (e) => {
    e.preventDefault();
    setIsLoading(true)

    if (!showBankDetailFields) {
      setCurrentStep(3);
      setPostDataErrorMessage("");
      setIsLoading(false);
      return; 
    }

    const jsonData = JSON.stringify(bankFormData);
    console.log('jsonData', jsonData)
  
    // Create post
    const config = {
      headers: {
        'Content-Type': 'application/json', // Use JSON content type
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      }
    };

    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_DOMAIN +'/post/create-post-bank-details/', jsonData, config);
      setIsLoading(false)

      if (response.status === 201) {
        setCurrentStep(3);
        setPostDataErrorMessage("")
      }
    } catch (error) {

      setIsLoading(false);
      if (error.response && error.response.data) {
        const errorData = error.response.data;

        if (errorData?.error && Array.isArray(errorData.error.error)) {
          setPostDataErrorMessage(errorData.error.error[0]); 
        } 
        else if (errorData?.error.account_number){
          setPostDataErrorMessage("account_number: " + errorData.error.account_number[0]); 
        } 
        else if (errorData?.error.account_holder){
          setPostDataErrorMessage("account_holder: " + errorData.error.account_holder[0]); 
        } 
        else if (errorData?.error.bank_name){
          setPostDataErrorMessage("bank_name: " + errorData.error.bank_name[0]); 
        } 
        else if (typeof errorData?.error === "string") {
          setPostDataErrorMessage(errorData.error);
        } else {
          setPostDataErrorMessage("An unknown error occurred.");
        }
      }else {
        setPostDataErrorMessage('An unexpected error occurred. Please try again.');
      };
    };
  };


  const content = (
    <>
      {currentState === 1 ? (
        <>
          <h2 className="mb-6 text-2xl dark:text-white">Basic Details</h2>

          <div className="pt-2 pb-5 space-y-5 ">
  
            <div className="flex flex-col">
              <label htmlFor="seeker" className="block font-semibold mb-2 dark:text-white">Seeker Name</label>
              <input
                type="text"
                id="seeker"
                name="seeker"
                placeholder='Name of Seeker: Bilal Ahmed'
                value={formData.seeker}
                onChange={handlePostDataChange}
                className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="address" className="block font-semibold mb-2 dark:text-white">Seeker Address</label>
              <input
                type="text"
                id="address"
                name="address"
                placeholder='Address: C.1-167, North Karachi,'
                value={formData.address}
                onChange={handlePostDataChange}
                className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="phone_number" className="block font-semibold mb-2 dark:text-white">Seeker Phone Number</label>
              <input
                type="tel"
                id="phone_number"
                name="phone_number"
                placeholder='03xx-xxxxxx21'
                value={formData.phone_number}
                onChange={handlePostDataChange}
                className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                required
                maxLength={11}
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="post_type" className="block font-semibold mb-2 dark:text-white">Asking for What?</label>
              <select
                id="post_type"
                name="post_type"
                value={formData.post_type}
                onChange={handlePostDataChange}
                className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                required
              >
                <option value="">Select Purpose</option>
                <option value="family">Asking for someone Family</option>
                <option value="person">Asking for Myself</option>
              </select>
            </div>

            <div className="flex flex-col">
              <label htmlFor="need" className="block font-semibold mb-2 dark:text-white">What Seeker needs?</label>
              <select
                id="need"
                name="need"
                value={formData.need}
                onChange={handlePostDataChange}
                className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                required
              >
                <option value="">Select Need</option>
                <option value="zakat">Zakat</option>
                <option value="donation">Donation</option>
                <option value="help">Help</option>
              </select>
            </div>

            <div className="flex flex-col">
            <label htmlFor="needed_money" className="block font-semibold mb-2 dark:text-white">How much money Seeker needs?</label>
              <input
                type="number"
                id="needed_money"
                name="needed_money"
                value={formData.needed_money}
                onChange={handlePostDataChange}
                className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                required
              />
            </div>

            <div className="flex flex-col">
                <label htmlFor="description" className="block font-semibold mb-2 dark:text-white">Seeker situation</label>
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handlePostDataChange}
                  className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                  required
                />
            </div>

          </div>

          <div className="text-center">
            {isLoading ? (
              <div className="inline-block loading-icon">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 dark:border-stone-100" />
              </div>
            ) : (

              <button
                onClick={SubmitPostData}
                className={`w-full rounded-md bg-blue-500 text-white font-extrabold px-4 py-2 hover:bg-blue-600 ${seekerVideoInProcess || placeVideoInProcess || docInProcess ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
              >
                Next
              </button>
            )}

            {postDataErrorMessage && <div style={{ color: 'red', fontWeight: 'bold' }}>{postDataErrorMessage}</div>}

            {(seekerVideoInProcess || placeVideoInProcess || docInProcess) && (
              <div style={{ color: 'red', fontWeight: 'bold', marginTop: '10px' }}>
                Please wait until the file is uploaded
              </div>
            )}
          </div>

        </>
        ) : currentState === 2 ?  (
          <>
            <h2 className="mb-6 text-2xl dark:text-white">Bank Details</h2>

            <div className="pt-2 pb-5 space-y-4">
              {/* Bank Name */}
              {showBankDetailFields && (
                <div className="flex flex-col space-y-2">
                  <label htmlFor="bank_name" className="block font-semibold mb-2 dark:text-white">
                    Bank Name
                  </label>
                  <input
                    type="text"
                    id="bank_name"
                    name="bank_name"
                    value={bankFormData.bank_name}
                    required
                    onChange={handleBankDataChange}
                    className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                  />
                </div>
              )}

              {/* Account Holder */}
              {showBankDetailFields && (
                <div className="flex flex-col space-y-2">
                  <label htmlFor="account_holder" className="block font-semibold mb-2 dark:text-white">
                    Account Holder Name
                  </label>
                  <input
                    type="text"
                    id="account_holder"
                    required
                    name="account_holder"
                    value={bankFormData.account_holder}
                    onChange={handleBankDataChange}
                    className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                  />
                </div>
              )}

              {/* Account Number */}
              {showBankDetailFields && (
                <div className="flex flex-col space-y-2">
                  <label htmlFor="account_number" className="block font-semibold mb-2 dark:text-white">
                    Account Number
                  </label>
                  <input
                    type="text"
                    id="account_number"
                    name="account_number"
                    required
                    value={bankFormData.account_number}
                    onChange={handleBankDataChange}
                    className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                  />
                </div>
              )}

              <hr />

              {/* No Bank Account Radio Button */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="no_bank_account" className="block font-semibold mb-2 dark:text-white">
                  <input
                    type="checkbox"
                    id="no_bank_account"
                    name="no_bank_account"
                    onChange={(e) => {setShowBankDetailFields(!e.target.checked)}}
                    className="mr-2 leading-tight"
                  />
                  <span className="text-sm">Seeker doesn't have a bank account</span>
                </label>
              </div>
            </div>
            
            {/* <CustomButton 
              label='Previous'
              className="mb-2 bg-black hover:bg-gray-800"
              onClick={() => setCurrentStep(1)}
            /> */}


          <div className="text-center">
            {isLoading ? (
              <div className="inline-block loading-icon">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 dark:border-stone-100" />
              </div>
            ) : (

              <button
                onClick={SubmitPostBankData}
                className={`w-full rounded-md bg-blue-500 text-white font-extrabold px-4 py-2 hover:bg-blue-600 ${seekerVideoInProcess || placeVideoInProcess || docInProcess ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
              >
                Next
              </button>
            )}

            {postDataErrorMessage && <div style={{ color: 'red', fontWeight: 'bold' }}>{postDataErrorMessage}</div>}

            {(seekerVideoInProcess || placeVideoInProcess || docInProcess) && (
              <div style={{ color: 'red', fontWeight: 'bold', marginTop: '10px' }}>
                Please wait until the file is uploaded
              </div>
            )}
          </div>

          </>
        ) : currentState ===3 ?  (
          <>
            <h2 className="mb-6 text-2xl dark:text-white">Upload Seeker Video</h2>

            <div className="pt-2 pb-5 space-y-4">
              <div className="flex flex-col space-y-2">
                <label htmlFor="seeker_video" className="block font-semibold mb-2 dark:text-white">Seeker Video</label>
                <input
                  type="file"
                  id="seeker_video"
                  name="seeker_video"
                  required
                  disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
                  onChange={handleSeekerFileChange}
                  className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                />
                {seekerVideoMessage && <div>{seekerVideoMessage}</div>}
              </div>
            </div>
            
            {/* <CustomButton 
              label='Previous'
              className="mb-2 bg-black hover:bg-gray-800"
              onClick={() => setCurrentStep(2)}
            /> */}

          <div className="text-center">
            {isLoading ? (
              <div className="inline-block loading-icon">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 dark:border-stone-100" />
              </div>
            ) : (

              <button
                onClick={handleSeekerFileChange}
                className={`w-full rounded-md bg-blue-500 text-white font-extrabold px-4 py-2 hover:bg-blue-600${seekerVideoInProcess || placeVideoInProcess || docInProcess ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
              >
                Next
              </button>
            )}

            {postDataErrorMessage && <div style={{ color: 'red', fontWeight: 'bold' }}>{postDataErrorMessage}</div>}

            {(seekerVideoInProcess || placeVideoInProcess || docInProcess) && (
              <div style={{ color: 'red', fontWeight: 'bold', marginTop: '10px' }}>
                Please wait until the file is uploaded
              </div>
            )}
          </div>


          </>
        )  : currentState === 4 ? (
          <>
            <h2 className="mb-6 text-2xl dark:text-white">Upload Place Video</h2>
            
            <div className="pt-2 pb-5 space-y-4">
              <div className="flex flex-col space-y-2">
                <label htmlFor="place_video" className="block font-semibold mb-2 dark:text-white">Place Video</label>
                <input
                  type="file"
                  id="place_video"
                  required
                  disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
                  name="place_video"
                  onChange={handlePlaceFileChange}
                  className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                />
                {placeVideoMessage && <div>{placeVideoMessage}</div>}
              </div>
            </div>

            {/* <CustomButton 
                label='Previous'
                className="mb-2 bg-black hover:bg-gray-800"
                onClick={() => setCurrentStep(3)}
              /> */}

            <div className="text-center">
              {isLoading ? (
                <div className="inline-block loading-icon">
                  <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 dark:border-stone-100" />
                </div>
              ) : (
                <button
                  type="submit"
                  onClick={handlePlaceFileChange}
                  className={`w-full rounded-md bg-blue-500 text-white font-extrabold px-4 py-2 hover:bg-blue-600 ${seekerVideoInProcess || placeVideoInProcess || docInProcess ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
                >
                  Next
                </button>
              )}

              {postDataErrorMessage && <div style={{ color: 'red', fontWeight: 'bold' }}>{postDataErrorMessage}</div>}

              {(seekerVideoInProcess || placeVideoInProcess || docInProcess) && (
                <div style={{ color: 'red', fontWeight: 'bold', marginTop: '10px' }}>
                  Please wait until the file is uploaded
                </div>
              )}
            </div>

          </>
        ) : (
          <>
            <h2 className="mb-6 text-2xl dark:text-white">Upload Documents/Proofs/Bills</h2>
            <form onSubmit={handleFileSubmit} className="pt-3 pb-6 space-y-4">
              <div className="flex flex-col space-y-2">
                <label htmlFor="documents" className="block font-semibold mb-2 dark:text-white">
                  Electric Bill
                </label>
                <input
                  type="file"
                  id="documents"
                  required
                  name="documents"
                  multiple
                  disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
                  onChange={handleFileSelection}
                  className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                />
                {docUploadMessage && (
                  <div className="text-sm mt-1">{docUploadMessage}</div>
                )}
              </div>
      
              <div className="text-center">
                {isLoading ? (
                  <div className="inline-block loading-icon">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 dark:border-stone-100" />
                  </div>
                ) : (
                  <button
                    type="submit"
                    className={`w-full rounded-md bg-blue-500 text-white font-extrabold px-4 py-2 hover:bg-blue-600 ${
                      seekerVideoInProcess || placeVideoInProcess || docInProcess ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
                  >
                    Submit
                  </button>
                )}
      
                {postDataErrorMessage && (
                  <div className="text-red-500 font-bold mt-2">{postDataErrorMessage}</div>
                )}
      
                {(seekerVideoInProcess || placeVideoInProcess || docInProcess) && (
                  <div className="text-red-500 font-bold mt-2">
                    Please wait until the file is uploaded
                  </div>
                )}
              </div>
            </form>
          </>
        ) 
      }
    </>
  )

  return (
    <>
      <Modal 
        isOpen={useCreatePostModal.isOpen}
        close={useCreatePostModal.close}
        label="Create Post"
        content={content}
      />
    </>
  )
}

export default CreatePostModal