import Modal from "./Modal"
import usePostUpdateModalHook from "../hooks/useUpdatePostModal"

const UpdatePostModal = ({slug}) => {
  const useHook = usePostUpdateModalHook()
  const content = (
    <>
      <h1>slug: {slug}</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem aut officia ducimus tempore obcaecati illum quo at? Iure, et officia totam omnis repellendus perspiciatis, recusandae ut consequatur aliquid architecto delectus?
      </p>
    </>
  )

  return (
    <>
      <Modal 
        isOpen={useHook.isOpen}
        close={useHook.close}
        label="Update Post"
        content={content}
      />
    </>
  )

}


export default UpdatePostModal;