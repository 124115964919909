import { useState } from 'react';
import Registration from './Registration';
import UserLogin from './Login';

const TabPanel = (props) => {
  const { children, value, index } = props;
  return (
    <div className={value === index ? '' : 'hidden'} role='tabpanel'>
      {value === index && <div>{children}</div>}
    </div>
  );
};

const AuthCard = () => {
  
  const [value, setValue] = useState(0);

  return (
    <>
      <div className="flex justify-center items-center h-screen AuthCard mt-20 ">
        <div className="bg-white rounded-lg shadow-lg dark:bg-gray-700 px-5 pt-6 pb-8 overflow-y-auto mt-20">
          <div className="border-b">
            <div className="flex justify-between items-center pb-4">
              <button
                className={`text-lg font-bold focus:outline-none ${value === 0 ? 'text-blue-500' : 'text-gray-500 dark:text-stone-50'}`}
                onClick={() => setValue(0)}>
                Login
              </button>
              <button 
                className={`text-lg font-bold focus:outline-none ${value === 1 ? 'text-blue-500' : 'text-gray-500 dark:text-stone-50'}`}
                onClick={() => setValue(1)}
              >
                Sign Up
              </button>
            </div>
          </div>

          <div className="mt-4">
            <TabPanel value={value} index={0}>
              <UserLogin />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Registration />
            </TabPanel>
          </div>

          <center>
            <p className="text-sm text-gray-500 dark:text-stone-50 mt-4">
              Our server is currently under maintenance. Sorry for your patience.
            </p>
          </center>

        </div>
      </div>
    </>
  );
};

export default AuthCard;
