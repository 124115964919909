import React from "react";
import JoinDonorCommunity from "../modals/JoinDonorCom";
import useJoinDonorCom from "../hooks/useJoinDonorCom";

const JoinDonorCommunityPage = ({ donorCount }) => {
  const uJoinDonorCom = useJoinDonorCom();
  
  const benefits = [
    "Access unlimited posts from those in need.",
    "Watch videos shared by the needy.",
    "Read insights and comments from other donors inside a post.",
    "Engage with posts by upvoting, downvoting, and viewing the donors list.",
    "Report posts to ensure transparency and authenticity.",
    "Review proof documents submitted by the needy.",
  ];

  return (
    <div className="min-h-screen flex items-center justify-center p-6 bg-gradient-to-r from-blue-50 to-blue-100 dark:from-gray-900 dark:to-gray-800 mt-10">
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-2xl p-10 max-w-4xl w-full text-center">
        <h1 className="text-4xl font-extrabold text-gray-900 dark:text-white mb-4">
          Join the Donor Community
        </h1>
        
        <p className="text-lg text-gray-600 dark:text-gray-400 mt-3">
          Become part of something bigger and make a real impact.
        </p>

        {/* Benefits Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-8">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="bg-blue-100 dark:bg-gray-700 p-6 rounded-lg flex items-start space-x-4 hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1"
            >
              <span className="text-blue-600 dark:text-blue-400 text-2xl">✔️</span>
              <p className="text-gray-800 dark:text-gray-200 text-left">{benefit}</p>
            </div>
          ))}
        </div>

        {/* Guidelines Section */}
        <div className="mb-8 bg-gray-50 dark:bg-gray-700 rounded-xl p-6 text-left">
          {/* Requirements Alert */}
          <div className="bg-blue-50 dark:bg-gray-600 border-l-4 border-blue-500 p-4 mb-6 rounded">
            <div className="flex items-center mb-2">
              <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <h3 className="font-bold text-gray-800 dark:text-white">Joining Requirements</h3>
            </div>
            <ul className="ml-6 list-disc text-gray-600 dark:text-gray-300">
              <li>Your Facebook account link</li>
              <li>You must be above 18 years old</li>
              <li>12 month active membership on RS. 500</li>
              <li>Make sure your Facebook name matches your name on the Payment Transaction Slip for verification</li>
            </ul>
          </div>

          {/* Important Notes */}
          <div>
            <h3 className="font-bold text-gray-800 dark:text-white mb-2">Important Notes:</h3>
            <ul className="ml-6 list-disc text-gray-600 dark:text-gray-300 space-y-2">
              <li>12-month active membership upon joining the community</li>
              <li>Refunds are not possible due to technical limitations</li>
              <li>The money you pay is used only to run AddaZakat, not for personal benefits</li>
              <li>Minimum Rs. 500 accepted, additional amounts welcome as Sadqa Jaria</li>
            </ul>
          </div>
        </div>

        <button
          className="px-8 py-3 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700 transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 shadow-md"
          onClick={uJoinDonorCom.open}
        >
          Join Now
        </button>


        <JoinDonorCommunity />
      </div>
    </div>
  );
};

export default JoinDonorCommunityPage;