import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { multiFormatDateString } from '../utils/DateConvertor';
import "../assets/css/post.css";
import CommentList from './comments';

import { Link } from 'react-router-dom';
// buttons
import { BiUpvote, BiSolidUpvote } from "react-icons/bi";
import { BiDownvote, BiSolidDownvote } from "react-icons/bi";
import { FaRegBookmark, FaBookmark } from "react-icons/fa";
import '../assets/css/comments.css';

// Modals
import DonorsModal from '../modals/DonorsModal';
import ReportModal from '../modals/ReportModal';
import SupportSeekerModal from '../modals/SupportSeekerModal';
import ViewDocument from '../modals/ViewDocument';
import UpdatePostModal from '../modals/UpdatePostModal';
import DeletePostModal from '../modals/DeletePostModal';

// Hooks
import useDonorsModal from '../hooks/useDonorsModal';
import useReportModal from '../hooks/useReportModal';
import usePostUpdateModalHook from '../hooks/useUpdatePostModal';
import usePostDeleteModalHook from '../hooks/usePostDeleteModal';

import useSupportSeekerModal from '../hooks/useSupportSeekerModal';
import useViewDocumentModal from '../hooks/useViewDocumentModal';
import MessagePopUp from './MessagePopUp';

import { useGetPostDetailQuery, useGetPostDocFilesQuery, 
        useDownvoteMutation, useUpvoteMutation, useSaveMutation, useCommentMutation, useGetCommentsQuery } from '../../services/postApis';


const PostDetailView = () => {

  const { slug } = useParams();
  const { data, isError, error, isLoading } = useGetPostDetailQuery(slug);
  console.log("data", data)
  const { data: docData } = useGetPostDocFilesQuery(slug);
  const uDonorsModal = useDonorsModal();
  const uReportModal = useReportModal();
  const uUpdatePostModal  = usePostUpdateModalHook();
  const uDeletePostModal = usePostDeleteModalHook();

  const uSupportSeekerModal = useSupportSeekerModal();
  const uViewDocumentModal = useViewDocumentModal();

  const [upvote, setUpvotes] = useState(0)
  const [downvote, setDownvote] = useState(0)
  const [isUpvoted, setIsUpvoted] = useState(false);
  const [isDownvoted, setIsDownvoted] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const { data: commentData } = useGetCommentsQuery(slug);
  const [commentCount, setCommentCount] = useState(0);
  const [isDonor, setIsDonor] = useState(false)
  const [isCreator, setIsCreator] = useState(false)
  const [messagePopup, setMessagePopup] = useState('')




  const [selectedDoc, setSelectedDoc] = useState(null);
  const openDocumentModal = (docKey) => {
    setSelectedDoc(docData.documents[docKey]);
    uViewDocumentModal.open();
  };


  useEffect(() => {
    if (data) {
      setUpvotes(data.upvote_count);
      setDownvote(data.downvote_count);
      setIsUpvoted(data.is_upvoted);
      setIsDownvoted(data.is_downvoted);
      setIsSaved(data.is_saved);
      setCommentCount(data.comment_count);
  
      // Check if the user is a donor
      const isDonor = localStorage.getItem("is_donor") === "true";
  
      setIsCreator(data.is_creator_access)
      // Set visibility based on donor status or if the user is the creator
      setIsDonor(isDonor || data.is_creator_access);
    }
  }, [data]);

  useEffect(() => {
    if (commentData) {
      setCommentList(commentData);
    }
  }, [commentData]);

  const [upvoteMutation] = useUpvoteMutation()
  const [downvoteMutation] = useDownvoteMutation()
  const [saveMutation] = useSaveMutation()
  const [commentMutation] = useCommentMutation()

  const handleUpvote = (event) => {
    event.stopPropagation();

    if (!isDonor) {
      setMessagePopup('only donors can upvote')
      return
    }

    if (!isUpvoted) {
      setUpvotes(upvote + 1)
      setIsUpvoted(true)
      if (isDownvoted) {
        setDownvote(downvote - 1)
        setIsDownvoted(false)
      };
      upvoteMutation(slug)
    } else {
      setUpvotes(upvote - 1)
      setIsUpvoted(false)
      upvoteMutation(slug)
    }
  }

  const handleDownvote = (event) => {
    event.stopPropagation();

    if (!isDonor) {
      setMessagePopup('only donors can downvote')
      return
    }

    if (!isDownvoted) {
      setDownvote(downvote + 1)
      setIsDownvoted(true)
      if (isUpvoted) {
        setUpvotes(upvote - 1)
        setIsUpvoted(false)
      };
      downvoteMutation(slug)
    } else {
      setDownvote(downvote - 1)
      setIsDownvoted(false)
      downvoteMutation(slug)
    }
  }

  const handleSave = (event) => {

    if (!isSaved) {
      setIsSaved(true)
      saveMutation(slug)
    } else {
      setIsSaved(false)
      saveMutation(slug)
    }
  }

  const handleSubmitComment = async (event) => {


    event.preventDefault();
    
    if (!isDonor) {
      setMessagePopup("Only donors can submit comments");
      return;
    }

    const formData = new FormData();
    formData.append("body", event.target.body.value);
    formData.append("slug", event.target.slug.value);

    try {
      const response = await commentMutation(formData);
      setCommentList([...commentList, response.data]);
      setCommentCount(commentCount + 1);
      event.target.reset();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleOpenDocument = (docKey) => {
    if (!isDonor) {
      setMessagePopup("Only donors can view documents");
      setTimeout(() => setMessagePopup(null), 2000); // Hide after 2 seconds
      return;
    }
  
    openDocumentModal(docKey);
  };
  

  if (isLoading) {
    return <div><CircularProgress />Loading...</div>;
  }
  if (isError) return <div>Error: {error.message}</div>;
  
  return (
    
    <div className='bg-white PostDetailView dark:bg-gray-700 text-dark-1 dark:text-gray-100 m-12 max-w-7xl p-5'>
      {messagePopup && <MessagePopUp message={messagePopup} />}

      <div className='grid grid-cols-2 gap-4'>
        <div className=''>
          {isDonor && data?.post_videos?.videos?.seeker_video? (
            <video className='detailVideo' controls controlsList="nodownload">
              <source src={data.post_videos.videos.seeker_video} type="video/mp4" />
            </video>
          ) : (
            <div className='flex justify-center items-center bg-gray-700 text-white opacity-80 m-10 border border-black p-10'>
              <span>
                {
                isCreator ? 
                  ("Videos is being uploaded")
                :
                  ("Only donor can watch needy's videos")
                }
              </span>
            </div>
          )}
        </div>
        <div className=''>
          {isDonor && data?.post_videos?.videos?.place_video? (
            <video className='detailVideo' controls controlsList="nodownload">
              <source src={data.post_videos.videos.place_video} type="video/mp4" />
            </video>
          ) : (
            <div className='flex justify-center items-center bg-gray-700 text-white opacity-80 m-10 border border-black p-10'>
              <span>
                {
                isCreator ? 
                  ("Videos is being uploaded")
                :
                  ("Only donor can watch needy's videos")
                }
              </span>
            </div>
          )}
        </div>
      </div>
      <hr className='m-3' />

      <div className='flex justify-between py-4'>

        <div className='flex'>
          <Link className='custom-link'
            to={`/profiles/${data.creator}`}>
            <p className='text-xs font-bold text-blue-500'>{data.creator}</p>
          </Link>

          <Link className='custom-link' onClick={handleUpvote}>
            {isUpvoted ? <BiSolidUpvote className='text-xl text-green-500' /> : <BiUpvote className='text-xl' />}
            <p className='text-xs font-bold text-blue-500'>{upvote}</p>
          </Link>
          <Link className='custom-link' onClick={handleDownvote}>
            {isDownvoted ? <BiSolidDownvote className='text-xl' /> : <BiDownvote className='text-xl' />}
            <p className='text-xs font-bold text-blue-500'>{downvote}</p>
          </Link>
          <Link className='custom-link' onClick={handleSave}>
            {isSaved ? <FaBookmark className='text-xl' /> : <FaRegBookmark className='text-xl' />}
          </Link>
        </div>

        <div className="flex justify-center space-x-4">
          
          {isCreator && (
            <>
              <button
                onClick={uUpdatePostModal.open}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded-lg"
              >
                Update 
              </button>

              <button
                onClick={uDeletePostModal.open}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded-lg"
              >
                Delete 
              </button>
            </>
          )}
          


          <button
            onClick={uDonorsModal.open}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded-lg"
          >
            Donate ({data.donors_count})
          </button>

          <button
            onClick={uReportModal.open} 
            className="bg-teal-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded-lg">
            Report ({data.report_count})
          </button>

          <button
            onClick={uSupportSeekerModal.open} 
            className="bg-slate-400 hover:bg-green-600 text-white font-bold py-1 px-2 rounded-lg">
            Support them
          </button>
        </div>

        {/* Render the Donate Modal */}
        <DonorsModal slug={data.slug} />
        <ReportModal slug={data.slug} />
        <SupportSeekerModal slug={data.slug}/>
        <UpdatePostModal slug={data.slug} />
        <DeletePostModal slug={data.slug} />

      </div>

        
      <div className="space-y-3 py-4">
        <h2 className="text-2xl font-semibold">Needy Details</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 font-semibold text-white py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
                Name: {data.seeker}
            </div>

            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
                Need: {data.need}
            </div>

            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
                Post Type: {data.post_type}
            </div>

            
            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
              Created at: {multiFormatDateString(data.created)}
            </div>
            
            {data?.post_videos?.videos?.seeker_video_prediction && (
              <div 
                className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
              >
                Seeker video prediction: {data.post_videos.videos.seeker_video_prediction}%
              </div>
              )
            }
            
            {data?.post_videos?.videos?.place_video_prediction && (
              <div 
                className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
              >
                Place video prediction: {data.post_videos.videos.place_video_prediction}%
              </div>
            )}

            {data?.human_verification > 0 && (
              <div 
                className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
              >
                  Human verification: {data.human_verification}%
              </div>
            )}
            

            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
                Total Need: {data.needed_money}
            </div>

            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
                Total Donated: {data.paid}
            </div>

            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
                Views: {data.views.length}
            </div>
            
            
            

            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
              Phone Number: {data.phone_number}
            </div>
            
            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
                Address: {data.address}
            </div>
        </div>
      </div>


      <div className="space-y-3 py-4">
        <h2 className="text-2xl font-semibold">Documents</h2>

        {messagePopup && <MessagePopUp message={messagePopup} />}
        
        {!docData && "Seeker did not provide any proof documents"}
        
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          {docData &&
            docData.documents &&
            Object.keys(docData.documents).map((docKey) => (
              <button
                key={docKey}
                onClick={() => handleOpenDocument(docKey)}
                className="flex items-center bg-blue-600 hover:bg-slate-300 text-white font-bold py-1 px-2 rounded-lg mx-2 hover:text-black truncate"
              >
                {docKey.replace(/_/g, " ")}
              </button>
            ))}
        </div>

        {/* Render the modal only when it's open */}
        {uViewDocumentModal.isOpen && <ViewDocument url={selectedDoc} />}
      </div>

      <div className='space-y-3 py-4'>
        <div className='text-2xl font-bold'>Discription</div>
        <p className='text-dark-1 dark:text-white'>{data.description}</p>
      </div>

      <div className='py-4 bg-white dark:bg-gray-700 '>
        <p className='text-2xl font-bold text-dark-1 dark:text-white'>{commentCount} Comments</p>

        <form onSubmit={handleSubmitComment} className=' bg-white dark:bg-gray-700 comment-form  '>
          <input type='text' placeholder='Add a comment...' name='body' className=' text-dark-1 dark:text-white bg-white dark:bg-gray-700' />
          <input type='hidden' value={data.slug} name='slug' />
          <button type='submit'>Comment</button>
        </form>

        {commentList && commentList.length > 0 ? (
            [...commentList].reverse().map(comment => (
                <CommentList key={comment.id} comment={comment} />
            ))
        ) : (
            <p>No comments yet.</p>
        )}


      </div>

    </div>
  );
}

export default PostDetailView
