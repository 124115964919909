import React from 'react';
import { useProfileQuery, useFollowOrUnfollowMutation } from '../../services/profileApis';
import { useGetLoggedUserQuery } from '../../services/userAuthApi';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import PostCard from '../../post/components/post-card';
import { useProfilePostQuery } from '../../services/postApis';
import ReportProfileModel from '../components/reportProfile';
import useUpdateProfileDetails from '../hooks/useUpdateProfileDetailsModal';
import UpdateProfieDetailsModal from '../modals/UpdateDetails';

const ProfileDetail = () => {
  const [follow, setFollow] = React.useState(false);
  const { username } = useParams();
  const updateProfileModalhook = useUpdateProfileDetails();
  const localUsername = JSON.parse(localStorage.getItem('user'));

  const { data, isError, error, isLoading } = useProfileQuery(username);
  const [followOrUnfollow] = useFollowOrUnfollowMutation();
  const { data: userDetail, isError: isErrorUser, error: userError, isLoading: isLoadingUser } = useGetLoggedUserQuery(username);
  const { data: posts, isSuccess } = useProfilePostQuery();

  const handleFollowOrUnfollow = async () => {
    try {
      await followOrUnfollow(username).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const handleFollowClick = () => {
    handleFollowOrUnfollow();
    setFollow(!follow);
  };

  if (isLoading || isLoadingUser) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <CircularProgress />
      </div>
    );
  }

  const isOwnProfile = localUsername?.username === username;
  if (isErrorUser) return <div className="text-red-500 text-center">Error: {userError.message}</div>;
  if (isError) return <div className="text-red-500 text-center">Error: {error.message}</div>;
  if (!userDetail) return <div className="text-red-500 text-center">Error: User details not found.</div>;

  return (
    <div className="container mx-auto mt-20 p-6">
      <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6">
        <div className="flex flex-col md:flex-row items-center md:items-start gap-6">
          <div className="relative">
            <img
              className="w-40 h-40 object-cover rounded-full border-4 border-blue-500 shadow-lg"
              src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn0.iconfinder.com%2Fdata%2Ficons%2Fhr-business-and-finance%2F100%2Fface_human_blank_user_avatar_mannequin_dummy-1024.png&f=1&nofb=1&ipt=7d8b5c0a27f5b224afff53a921c77bd1b5756d78196b3772e822d00cebe5b301&ipo=images"
              alt={username}
            />
          </div>

          <div className="flex-1 text-center md:text-left">
            <h1 className="text-3xl font-semibold text-gray-900 dark:text-white">{userDetail.full_name}</h1>
            <p className="text-gray-500 dark:text-gray-300">@{userDetail.username}</p>
            <p className="mt-2 text-gray-600 dark:text-gray-400">{data.slogan}</p>
            <p className="mt-4 text-gray-700 dark:text-gray-300">{data.intro}</p>

            <div className="mt-6 flex gap-4 justify-center md:justify-start">
              {!isOwnProfile && (
                <button
                  className="px-5 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded-lg shadow-md transition"
                  onClick={handleFollowClick}
                >
                  {follow ? 'Unfollow' : 'Follow'}
                </button>
              )}

              {!isOwnProfile && (
                <button className="px-5 py-2 bg-gray-700 hover:bg-gray-800 text-white rounded-lg shadow-md transition">
                  <ReportProfileModel username={username} count={data?.report_count} />
                </button>
              )}

              {isOwnProfile && (
                <button
                  onClick={() => updateProfileModalhook.open()}
                  className="px-5 py-2 bg-gray-700 hover:bg-gray-800 text-white rounded-lg shadow-md transition"
                >
                  Edit Profile
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-10">
        <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">Posts</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {isSuccess && posts?.length > 0 ? (
            posts.map((post) => <PostCard key={post.id} post={post} />)
          ) : (
            <p className="text-gray-500 dark:text-gray-300">No posts available.</p>
          )}
        </div>
      </div>

      {updateProfileModalhook.isOpen && <UpdateProfieDetailsModal username={username} />}
    </div>
  );
};

export default ProfileDetail;
