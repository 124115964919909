import PostList from '../components/post-list';

const PostHome = () => {
  return (
    <div className="container">
      <PostList endpoint="/post/get-posts/" />
    </div>
  );
};

export default PostHome;