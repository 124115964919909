import React, { useState } from "react";
import axios from "axios";
import Modal from "./Modal";
import useJoinDonorCom from "../hooks/useJoinDonorCom";
import MessagePopUp from "../components/MessagePopUp";

const JoinDonorCommunity = () => {
  const uJoinDonorCom = useJoinDonorCom();
  const [currentStep, setCurrentStep] = useState(1);
  const [fburl, setFburl] = useState("");
  const [transactionProof, setTransactionProof] = useState(null);
  const [filePreview, setFilePreview] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleFbUrlChange = (e) => {
    const url = e.target.value;
    setFburl(url); // Always update the input value to allow editing
  
    const regex = /^https:\/\/(www\.)?facebook\.com\/[a-zA-Z0-9_.-]+\/?$/;
  
    if (url.length === 0) {
      setErrorMessage("Please enter a valid Facebook Link");
    } else if (!regex.test(url)) {
      setErrorMessage(`This is an invalid Facebook Link: ${url}`);
    } else {
      setErrorMessage("");
    }
  };

  const handleTransactionProof = (e) => {
    const file = e.target.files[0];
    if (file) {
      setTransactionProof(file);
      setFilePreview(URL.createObjectURL(file));
    }
  };

  const validateStep1 = () => {
    if (!fburl) {
      setErrorMessage("Please enter your Facebook Link.");
      return false;
    }
    setErrorMessage("");
    return true;
  };

  const handleNextStep = () => {
    if (validateStep1()) {
      setCurrentStep(2);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!fburl || !transactionProof) {
      setErrorMessage("Please fill in all fields.");
      return;
    }
  
    const formData = new FormData();
    formData.append("socials", fburl);
    formData.append("documents", transactionProof);
  
    setIsLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_DOMAIN + "/user/create-membership/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.status === 200) {
        setShowSuccessMessage(true);
        setTimeout(() => {
          uJoinDonorCom.close();
          setShowSuccessMessage(false);
        }, 3000);
      }
    } catch (error) {
      if (error.response?.data?.error) {
        // Convert object errors into an array of messages
        const errorData = error.response.data.error;
        const formattedErrors = Object.values(errorData).flat(); 
        setErrorMessage(formattedErrors.join(", "));
      } else {
        setErrorMessage("An error occurred while submitting the form.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getYouTubeShortId = (url) => {
    const shortId = 'zG0kbWZYuQo'; 
    return shortId;
  };


  return (
    <>
      <Modal
        isOpen={uJoinDonorCom.isOpen}
        close={uJoinDonorCom.close}
        label="Join the Donor Community"
        content={
          <div className="p-6">
            {currentStep === 1 ? (
              <form className="space-y-6" onSubmit={(e) => e.preventDefault()}>

                <div className="relative w-full max-w-[280px] mx-auto m-4">
                  <label className="text-gray-800 font-bold dark:text-white w-full">This is how you find your Facebook Profile Link</label>
                  <div className="relative h-10 pb-[177.77%] bg-gray-100 rounded-lg overflow-hidden">
                    <iframe
                      src={`https://www.youtube.com/embed/${getYouTubeShortId()}?mute=1`}
                      className="absolute inset-0 w-full h-full"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="YouTube Shorts video"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-lg font-medium text-gray-800 mb-2 dark:text-white">
                    Your Facebook Profile Link
                  </label>
                  
                  <input
                    type="url"
                    value={fburl}
                    onChange={handleFbUrlChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                    placeholder="Enter your Facebook URL"
                    required
                  />
                </div>
                {errorMessage && (
                  <p className="text-red-500 text-sm mt-2 dark:text-white font-bold text-gray-800">{errorMessage}</p>
                )}
                <button
                  type="button"
                  onClick={handleNextStep}
                  className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all"
                >
                  Next
                </button>
              </form>
            ) : (
              <form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
                <div className="bg-blue-50 p-4 rounded-lg">
                  <h3 className="text-lg font-semibold text-blue-800 mb-2">
                    Note
                  </h3>
                  <p className="text-gray-700">
                    The transaction slip must have the same name as your Facebook account name; otherwise, your account will not be approved.
                  </p>
                </div>
  
                <div className="bg-green-50 p-6 rounded-lg max-w-lg mx-auto overflow-auto">
                  <h3 className="text-lg font-semibold text-green-800 mb-4 text-center">
                    Transfer Details
                  </h3>
                  <p className="text-gray-700 text-center mb-4">
                    You have to transfer <span className="font-bold">500</span> to the following account:
                  </p>
                  
                  <div className="grid grid-cols-2 gap-y-3 border-t border-green-200 pt-4">
                    <span className="font-medium text-gray-800 text-left">Account Title:</span>
                    <span className="text-gray-700 text-left">Abu Ubaida</span>

                    <span className="font-medium text-gray-800 text-left">Account Number:</span>
                    <span className="text-gray-700 text-left">01630107264391</span>

                    <span className="font-medium text-gray-800 text-left">IBAN:</span>
                    <span className="text-gray-700 text-left">PK78MEZN0001630107264391</span>
                  </div>
                </div>
  
                <div>
                  <label className="block text-lg font-medium text-gray-800 mb-2 dark:text-white font-bold">
                    Upload Transaction Proof
                  </label>
                  <input
                    type="file"
                    onChange={handleTransactionProof}
                    accept="image/*, application/pdf"
                    className="w-full p-3 border dark:text-white border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                    required
                  />
                </div>
  
                {filePreview && (
                  <div className="mt-4">
                    <p className="text-gray-700 font-semibold mb-2 dark:text-white">File Preview</p>
                    {transactionProof.type.startsWith("image/") ? (
                      <img
                        src={filePreview}
                        alt="File Preview"
                        className="w-full max-h-64 object-cover rounded-lg border border-gray-300 shadow-sm"
                      />
                    ) : (
                      <a
                        href={filePreview}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 underline hover:text-blue-700 transition-all"
                      >
                        View File
                      </a>
                    )}
                  </div>
                )}
  
                {errorMessage && (
                  <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
                )}
  
                <div className="flex gap-4">
                  <button
                    type="button"
                    onClick={() => setCurrentStep(1)}
                    className="w-1/2 bg-gray-500 text-white py-3 rounded-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-all"
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={isLoading || !transactionProof}
                    className={`w-1/2 py-3 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all ${
                      isLoading
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-green-600 hover:bg-green-700 focus:ring-green-500"
                    }`}
                  >
                    {isLoading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </form>
            )}
          </div>
        }
      />
      {showSuccessMessage && (
        <MessagePopUp message="Thank you for joining the donor community!" />
      )}
    </>
  );
};

export default JoinDonorCommunity;
