import { Outlet } from "react-router-dom";
import Navbar from "../../main/mainPages/Navbar";

const ProfileLayout = () => {
  return (
    <div className="flex flex-col h-screen">
      <Navbar />

      <div className="flex flex-1 overflow-hidden">
        <div className="flex-1 p-1 overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default ProfileLayout;