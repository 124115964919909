import React from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import PostCard from './post-card';
import MessagePopUp from './MessagePopUp';

const PostList = ({ endpoint, queryParams = "" }) => {
  const [myErrors, setMyErrors] = React.useState('');

  const fetchPosts = async ({ pageParam = 1 }) => {
    try {
      const token = localStorage.getItem('access_token');
      const headers = token ? { Authorization: `Bearer ${token}` } : {};

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_DOMAIN}${endpoint}?page=${pageParam}&${queryParams}`,
        { headers }
      );

      if (!response.ok) {
        setMyErrors('Currently, we are working harder to make our website better.');
      }

      return await response.json();
    } catch (error) {
      setMyErrors('We are working on it. Please try again later.');
    }
  };

  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['posts', endpoint, queryParams],
    queryFn: ({ pageParam = 1 }) => fetchPosts({ pageParam }),
    getNextPageParam: (lastPage) => {
      if (lastPage?.next) {
        try {
          const url = new URL(lastPage.next);
          const nextPage = url.searchParams.get('page');
          return nextPage ? parseInt(nextPage, 10) : undefined;
        } catch (error) {
          console.error("Invalid URL:", lastPage.next, error);
          return undefined; // Fallback to no more pages
        }
      }
      return undefined; // No more pages
    },
  });


  return (
    <div className="container mx-auto m-16">
      
      {isLoading && <p>Loading...</p>}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-10 w-full gap-2">
        {data?.pages?.some((page) => page?.results?.length) ? (
          data.pages.map((page) =>
            page.results.map((post) => (
              <PostCard key={post.id} post={post} />
            ))
          )
        ) : (
          <MessagePopUp message={myErrors || "server is unavailable at the moment "}/>
        )}
      </div>

      {hasNextPage && (
        <button
          className="w-auto mt-6 mb-5 p-3 rounded-full bg-gray-500 text-white dark:bg-gray-600 hover:bg-gray-400 dark:text-white font-bold"
          onClick={() => fetchNextPage()}
          disabled={isFetchingNextPage}
        >
          {isFetchingNextPage ? 'Loading more...' : 'Load More'}
        </button>
      )}
    </div>
  );
};

export default PostList;