import { useCallback, useEffect, useState } from 'react';


const Modal = ({
  label,
  content,
  isOpen,
  close

}) => {
  const [showModal, setShowModal] = useState(isOpen)

  useEffect(() => {
    setShowModal(isOpen)
  }, [isOpen])

  const handleClose = useCallback(() => {
    setShowModal(false);

    setTimeout(() => {
      close();
    }, 300)

  }, [close])

  if (!isOpen){
    return null;
  }

  return (
    <div className="flex items-center justify-center fixed inset-0 z-50 bg-black/60 ">
      <div className="relative w-[90%] md:w-[80%] lg:w-[700px] my-6 h-auto max-h-[90%] overflow-y-auto">
        
        <div className={` transition duration-600 h-full ${showModal? 'translate-y-0  opacity-100': 'translate-y-full  opacity-10' } `}>
          <div className="w-full h-auto rounded-xl relative flex flex-col bg-white dark:bg-gray-700">
            
            <header className="h-[60px] flex items-center p-6 rounded-t justify-center relative border-b">
              

              <h2 className="font-bold dark:text-white text-2xl ">{label}</h2>
              <div 
                onClick={handleClose}
                className="p-3 absolute right-3 hover:bg-gray-300 rounded-full cursor-pointer dark:hover:bg-gray-500 "
                >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 dark:text-white ">
                  <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                </svg>
              </div>
            </header>
            <hr />

            <section className="p-5">
              {content}
            </section>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default Modal